<template>
  <div class="not-found">
    <div class="not-found-inner">

      <div class="page">

        <div class="content-wrapper">
          <div class="content">
            <h1>{{ $translations.getTranslation('404_page_not_found_title1') }}</h1>

            <p>
              {{ $translations.getTranslation('404_page_not_found_label1') }}
            </p>
          </div>

          <div class="navigation">
            <h2>{{ $translations.getTranslation('404_page_not_found_title2') }}</h2>

            <nav>
              <ul>
                <li>
                  <a :href="getPath('LinkCard')" @click.prevent="goTo('LinkCard')">
                    {{ $translations.getTranslation('404_page_not_found_link1') }}
                  </a>
                </li>
              </ul>
            </nav>

          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
  import { mixinRoutesHandler } from '@/mixins/routesHandler';

  export default {
    name: 'NotFound404',

    mixins: [mixinRoutesHandler],

    methods: {
      goTo(routeName) {
        this._mixinGoToRouteName(routeName);
      },

      getPath(routeName) {
        return this._mixinGetRoutePath(routeName);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .not-found {
    width: 100%;
    margin-top: 10px;

    .not-found-inner {
      width: 100%;
      background-color: #3ba9dc;
      color: white;

      .page {
        width: 100%;
        max-width: $appWidth;
        margin: auto;
        padding: 32px 8px;

        .content {
          padding: 0 32px;

          h1 {
            font-family: merriweather-heavy,TimesNewRoman,Times New Roman,Times,Baskerville,Georgia,serif;
            font-weight: 400;
            -webkit-font-smoothing: antialiased;
            font-size: 2.5rem;
            margin: 0 0 .5em;
            line-height: 1.4;
          }

          p {
            font-family: Times,Times New Roman,Georgia,serif;
            font-size: 16px;
            line-height: 1.4;
            -webkit-font-smoothing: subpixel-antialiased;
          }
        }

        .navigation {
          background-color: rgba(11,103,178,.3);
          padding: 16px;

          h2 {
            margin-bottom: 4px;
          }

          ul {
            padding: 0;
            margin: 0;
            display: flex;
            flex-wrap: wrap;

            li {
              flex: 0 1 49%;
              max-width: 49%;
              padding-bottom: 1em;
            }
          }

          a {
            font-size: 16px;
            color: white;
            background-image: url(https://styleguide.fdm.dk/images/icons/arrow-menu-white.svg);
            background-repeat: no-repeat;
            background-size: 16px;
            padding-left: 1.5em;
            background-position: 0 .25em;
          }
        }
      }
    }

    @media (min-width: $mdBreakpoint) {
      .not-found-inner {
        width: calc(100% - 64px);
        background-color: #3ba9dc;
        margin: 32px;

        .page {
          width: 100%;
          max-width: $appWidth;
          min-height: 960px;
          margin: auto;
          padding: 48px;

          .content-wrapper {
            width: 50%;
          }

          .content {
            padding: 0;
          }
        }
      }
    }

    @media (min-width: $lgBreakpoint) {
      .not-found-inner {
        .page {
          .content-wrapper {
            width: 33.3%;
          }
        }
      }
    }

    @media (min-width: 1600px) {
      .not-found-inner {
        .page {
          padding: 48px 0;
        }
      }
    }
  }
</style>
